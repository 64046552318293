<template>
  <div class="col s12 m6 l4">
    <div class="card light-blue bill-card">
      <div class="card-content white-text">
        <span class="card-title">{{$t('billPage.BillInCurrency')}}</span>
        <p v-for="cur of currencyInfoUa" :key="cur.name" class="currency-line">
          <span>{{getCurrency(cur.rate) | currencyView(cur.name)}}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bank',
  props: ['currencyInfo'],
  computed: {
    base () {
      return this.$store.getters.info.bank
    },
    currencyInfoUa () {
      return [{ name: 'UAH', rate: 1 }, ...this.currencyInfo]
    }
  },
  methods: {
    getCurrency (currency) {
      return Math.floor(this.base / currency)
    }
  }
}
</script>

<style scoped>

</style>
