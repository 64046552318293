<template>
  <div>
    <div class="page-title">
      <h3>{{$t('billPage.Bill')}}</h3>
      <button class="btn waves-effect waves-light btn-small" @click="refreshCurrency">
        <i class="material-icons">refresh</i>
      </button>
    </div>

    <loader v-if="loading"/>
    <div class="row" v-else>
      <home-bank
        :currencyInfo="currencyInfo"
      />
      <home-rate
        :currencyInfo="currencyInfo"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import homeRate from '@/components/home/Rate'
import homeBank from '@/components/home/Bank'

export default {
  name: 'Home',
  data: () => ({
    loading: true,
    currencyInfo: null
  }),
  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.$t('menu.Bill')
    }
  },
  async mounted () {
    this.currencyInfo = await this.$store.dispatch('fetchCurrency')
    this.loading = false
  },
  components: {
    homeBank,
    homeRate
  },
  methods: {
    async refreshCurrency () {
      this.loading = true
      this.currencyInfo = await this.$store.dispatch('fetchCurrency')
      this.loading = false
    }
  }
}
</script>
