<template>
  <div class="col s12 m6 l8">
    <div class="card orange darken-3 bill-card">
      <div class="card-content white-text">
        <div class="card-header">
          <span class="card-title">{{$t('billPage.Currency')}}</span>
        </div>
        <table>
          <thead>
          <tr>
            <th>{{$t('billPage.CurrencyName')}}</th>
            <th>{{$t('billPage.CurrencyRate')}}</th>
            <th>{{$t('billPage.CurrencyDate')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="cur of currencyInfoTest" :key="cur.name">
            <td>{{cur.name}}</td>
            <td>{{cur.rate | currencyView('UAH')}}</td>
            <td>{{ $d(cur.date, 'date') }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rate',
  props: ['currencyInfo'],
  computed: {
    currencyInfoTest () {
      return this.currencyInfo
    }
  }
}
</script>

<style scoped>

</style>
